<template>
    <ValidationObserver
        class="material-edit"
        tag="div"
        ref="observer"
        v-if="isLoaded"
    >
        <div class="material-edit__block material-edit__block--share">
            <div class="material-edit__block-body material-edit__share">
                <button :disabled="isSending" @click="onSubmit" class="v-btn">
                    Сохранить изменения
                </button>
                <router-link
                    :to="
                        materialId
                            ? `/knowledge/article/${materialId}`
                            : `/knowledge`
                    "
                >
                    <icon>
                        <path
                            d="M17 1L1 17"
                            stroke="#989CAE"
                            stroke-width="2"
                        ></path
                        ><path
                            d="M1 1L17 17"
                            stroke="#989CAE"
                            stroke-width="2"
                        ></path>
                    </icon>
                </router-link>
            </div>
        </div>

        <div class="material-edit__block material-edit__block--content">
            <div class="material-edit__block-body material-edit__content">
                <ValidationProvider
                    tag="div"
                    vid="text"
                    v-slot="{ errors }"
                    :rules="`required`"
                >
                    <new-editor
                        class="content__editor"
                        v-model="form.text"
                        :data="form.text"
                        :top-title="form.title"
                        top-placeholder="Название материала"
                        bottom-placeholder="Описание материала"
                    />
                    <span
                        class="v-error animated fadeInDown"
                        id="error_text"
                        v-if="errors.length"
                    >
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>
            </div>
        </div>
    </ValidationObserver>
    <loading-spinner v-else />
</template>

<script>
    import session from '@/api/session'
    import { mapGetters } from 'vuex'
    import Icon from '@/components/icon/Icon'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import { metaTitle } from '@/mixins/meta_title'
    import NewEditor from '@/components/editor/newEditor'

    export default {
        name: 'MaterialChange',
        mixins: [metaTitle],
        components: {
            NewEditor,
            LoadingSpinner,
            Icon
        },
        data() {
            return {
                form: {
                    timepiece: 20
                },
                isLoaded: false,
                isSending: false,
                isMaterialAdded: false
            }
        },
        computed: {
            ...mapGetters('default_data', ['knowledgeBaseId']),
            setTitle() {
                return 'Редактировать материал'
            }
        },
        methods: {
            async onSubmit() {
                const isValid = await this.$refs.observer.validate()
                if (isValid) {
                    this.$Progress.start()
                    const { form } = this
                    const data = {
                        ...form,
                        text: form.text.html
                    }
                    const content = form.text.json.content
                    const contentTitle = content.find(
                        item => item.type === 'title'
                    ).content
                    if (!content.length || !contentTitle) {
                        this.$refs.observer.setErrors({
                            text: ['Введите название материала']
                        })
                        this.$nextTick(() => {
                            this.$scrollTo('#error_text', { offset: 300 })
                        })
                        this.isSending = false
                        return false
                    } else {
                        data.title = contentTitle.map((variable) => variable.text).join('')
                    }
                    const isPublished = this.form.published
                    try {
                        const url = `/api/v1/material/${this.knowledgeBaseId}/`
                        data.is_draft = !isPublished
                        await session.put(url, data)
                        await session.post(
                            `/api/v1/material/${this.knowledgeBaseId}/publish/`
                        )

                        this.$router.push('/knowledge/')
                    } catch (err) {
                        // this.errorHandler(err);
                    }
                    this.$Progress.finish()
                }
            },
            async onLoad() {
                const request = await session.get(
                    `/api/v1/material/${this.knowledgeBaseId}/data/`
                )
                this.form = request.data
            }
        },
        async created() {
            await this.onLoad()
            this.isLoaded = true
        }
    }
</script>
<style lang="scss" scoped>
    @import '#sass/v-style';
    .material-edit {
        width: 100%;
        height: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        &__block {
            width: 100%;
            &-body {
                display: flex;
                width: 100%;
                max-width: 1024px;
                margin: 0 auto;
            }
            &--share {
                z-index: 1000;
                position: fixed;
                top: 0;
                left: 0;
                margin-left: 300px;
                width: calc(100% - 300px);
                box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
                background-color: #fff;
                @include below(1000px) {
                    margin-left: 0;
                    width: 100%;
                    top: 60px;
                }
                @include below(768px) {
                    margin-left: 0;
                    width: 100%;
                    top: auto;
                    bottom: 0;
                    z-index: 99999;
                }
            }
            &--content {
                margin-top: 100px;
                @include below(768px) {
                    margin-top: 0;
                }
            }
        }
        &__share {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            @include below(1366px) {
                padding: 20px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            width: 100%;
            .content {
                &__control-panel {
                    display: flex;
                    align-items: center;
                    @include below(500px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .control-panel {
                        &__item {
                            display: flex;
                            align-items: center;
                            color: #000;
                            &:nth-child(n + 2) {
                                margin-left: 30px;
                                @include below(500px) {
                                    margin-left: 0;
                                }
                            }
                        }
                        &__margin {
                            margin-left: 10px;
                        }
                        &__permission {
                            cursor: pointer;
                        }
                    }
                }
                &__editor {
                    width: 100%;
                }
            }
        }
    }
</style>
<style lang="scss">
    @import '#sass/v-style';
    #id_timer {
        position: relative;
        &::after {
            position: absolute;
            transform: translate(-50%, -50%);
            right: 0;
            top: 50%;
            content: 'мин';
            font-size: 0.875rem;
            display: block;
            z-index: 99;
            color: $text-lightgray;
        }
        .number-input__input {
            padding-right: 45px;
        }
    }
</style>
